<template>
  <v-card>
    <v-card-title class="headline">
      <span v-if="!edit">Ajouter</span>
      <span v-if="edit">Modifier</span>
      &nbsp;Apprenant·e
      <span v-if="!edit">·s</span>
    </v-card-title>

    <v-card-text>
      <v-form @submit.prevent="add()">
        <v-switch v-if="!edit" label="Liste" v-model="multiple"></v-switch>
        <v-combobox required v-model="promo" :items="promos" chips label="Promo"></v-combobox>
        <v-textarea
          label="Liste au format : Nom,Prénom,Date de naissance(jj/mm/aaaa);
          Nom,Prénom,Date de naissance; ..."
          name="list"
          v-model="list"
          v-if="multiple"
          outline
        ></v-textarea>
        <v-container v-if="!multiple">
          <v-text-field required name="name" label="Nom" v-model="single.name"></v-text-field>

          <v-text-field required name="firstname" label="Prénom" v-model="single.firstName"></v-text-field>

          <v-text-field
            required
            name="birthdate"
            label="Date de naissance (jj/mm/aaaa)"
            v-model="single.birthdate"
          ></v-text-field>
        </v-container>

        <v-btn color="success" type="submit">
          <span v-if="edit">Modifier</span>
          <span v-if="!edit">Ajouter</span>
        </v-btn>
        <v-btn color="error" v-if="edit" @click="remove()">Supprimer</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["promos"])
  },
  props: ["student"],
  data() {
    return {
      promo: "",
      single: {
        name: "",
        firstName: "",
        birthdate: "",
        projects: []
      },
      multiple: false,
      list: "",
      edit: false
    };
  },
  watch: {
    student: function(student) {
      if (student) {
        Object.assign(this.single, student);
        this.promo = student.promo;
        this.edit = true;
        this.multiple = false;
      } else {
        this.promo = "";
        this.edit = false;
        this.single = {};
      }
    }
  },
  mounted() {},
  methods: {
    add() {
      if (this.multiple) {
        let splitted = this.list.split(";");
        this.$store.dispatch("addStudents", {
          students: splitted,
          promo: this.promo
        });
      } else {
        let data = {
          name: this.single.name.trim(),
          firstName: this.single.firstName.trim(),
          birthdate: this.single.birthdate.trim(),
          promo: this.promo,
          projects: this.single.projects
        };
        if (this.edit) {
          this.$store.dispatch("updateStudent", {
            data,
            id: this.single.id
          });
        } else {
          this.$store.dispatch("addStudent", data);
        }
      }
    },
    remove() {
      this.$store.dispatch("removeStudent", this.single).then(() => {
        this.single = {};
        this.edit = false;
      });
    }
  }
};
</script>

<style>
</style>
