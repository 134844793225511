<template>
  <div>
    <v-navigation-drawer permanent clipped app>
      <v-container grid-list-xs>
       <v-combobox
          v-model="projectFilter"
          :items="refList"
          label="Filtrer les projets"
          multiple
          chips
          
        ></v-combobox> 
      </v-container>
       
      <v-list>
        <v-list-tile @click="selectProject(null)">
          <v-list-tile-action>
            <v-icon>add</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Nouveau Projet</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          :class="{active:(selected && project.id === selected.id)}"
          v-for="project in filteredProjects"
          :key="project.id"
          @click="selectProject(project)"
        >
          <v-list-tile-content>
            <v-list-tile-title>{{ project.name }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-container grid-list-md>
        <p v-if="!selected">Sélectionner un projet</p>
        <v-form v-if="selected">
          <v-text-field name="name" label="Nom du projet" v-model="selected.name"></v-text-field>

          <v-textarea
            name="description"
            label="Description du projet"
            v-model="selected.description"
          ></v-textarea>

          <v-select
            :items="refList"
            v-model="selected.referential"
            label="Référentiel du projet"
            
          ></v-select>
          <v-btn color="info" @click="addSkill()">Ajouter une compétence</v-btn>
          <v-layout row wrap>
            <v-flex
              xs12
              md6
              mt-3
              class="skill"
              v-for="(skill, index) in selected.skills"
              :key="index"
            >
              <button class="v-chip__close rm-button" @click="rmSkill(index)">
                <i aria-hidden="true" class="v-icon material-icons theme--light">cancel</i>
              </button>
              <v-select
                :items="projectRef"
                @change="onSelect(skill,$event)"
                :value="skill.activity+':'+skill.skill"
                label="Choisir une compétence"
              ></v-select>

              <v-text-field
                name="skill-description"
                label="Description de la compétence"
                v-model="skill.description"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-btn color="success" @click="save()">Sauvegarder Projet</v-btn>
          <v-btn color="error" v-if="selected.id" @click="remove()">Supprimer Projet</v-btn>
        </v-form>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import referentiel from '../referentiel.json';
import { mapState } from "vuex";
export default {
  name: "home-page",
  components: {
  },
  computed: {
    ...mapState(["projects"]),
    projectRef() {
      return referentiel[this.selected.referential].competences;
    },
    filteredProjects() {
      return this.projects.filter(project => this.projectFilter.find(item => item === project.referential) );
    }
  },
  data() {
    return {
      selected: null,
      drawer: null,
      refList: Object.keys(referentiel),
      skillList: referentiel.DWWM,
      projectFilter: Object.keys(referentiel)
    };
  },
  methods: {
    onSelect(skill, event) {
      let [activity, num] = event.split(":");
      skill.activity = activity;
      skill.skill = num;
    },
    addSkill() {
      this.selected.skills.push({
        activity: null,
        skill: null,
        description: ""
      });
    },
    rmSkill(index) {
      this.selected.skills.splice(index, 1);
    },
    selectProject(project) {
      if (project) {
        this.selected = project;
      } else {
        this.selected = {
          name: "",
          description: "",
          skills: []
        };
      }
    },
    save() {
      if (this.selected.id) {
        this.$store.dispatch('updateProject', {
          data: this.selected,
          id: this.selected.id
        });
      } else {
        this.$store.dispatch('addProject', this.selected);
      }
    },
    remove() {
      this.$store.dispatch('removeProject', this.selected);
      this.selected = null;
    }
  }
};
</script>


<style>
.active {
  background-color: #ffcdd2;
}
.rm-button {
  position: absolute;
  top: -15px;
  right: 0;
}
.skill {
  position: relative;
}
</style>
