import { getBinaryContent } from "pizzip/utils";
import * as ImageModule from 'docxtemplater-image-module-free';
import PizZip from "pizzip";
import * as Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import refs from './referentiel.json'
import {Buffer} from 'buffer';




export function curDate() {
    let today = new Date();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    let formated = dd + '/' + mm + '/' + yyyy;
    return formated;
}

export function createData(student, projects, fem, formateur, responsable, referentiel, location, placeholder = '[apprenant]', pronom = '[pronom]') {
    let activites = refs[referentiel].activites;

    let refProjects = projects.filter(item => item.referential === referentiel);

    let pron = fem ? 'elle' : 'il';
    let parser = (sentence) => sentence.replace(placeholder, student.firstName).replace(pronom, pron);
    let data = {
        referentiel: referentiel,
        titre: refs[referentiel].titre,
        dateJO: refs[referentiel].date.jo,
        dateMAJ: refs[referentiel].date.maj,
        dateEffet: refs[referentiel].date.effet,
        dateArrete: refs[referentiel].date.arrete,
        millesime: refs[referentiel].millesime,
        code: refs[referentiel].code,
        niveau: refs[referentiel].niveau,
        female: fem,
        name: student.name,
        firstName: student.firstName,
        birthdate: student.birthdate,
        formateur: formateur,
        responsable: responsable,
        lieu: location,
        activites: [],
        today: curDate()
    };

    for (let x = 0; x < activites.length; x++) {
        let actNum = x + 1;
        data.activites[x] = {};
        data.activites[x].actNum = actNum;
        data.activites[x].actName = activites[x];
        data.activites[x].comps = refs[referentiel].competences.filter(item => item.value.match('^' + actNum + ':')).map((item, index) => Object.create({ num: index + 1, text: item.text }));
        data.activites[x].projet1 = []
        data.activites[x].projet2 = []

        let activityProjects = refProjects.filter(project => project.skills.filter(skill => skill.activity == actNum).length > 0);
        let projectIterator = activityProjects.values();
        for (let y = 0; y < 10; y++) {
            let item = projectIterator.next();
            if (item.done) {
                break;
            }
            let value = item.value;
            let stuPro = student.projects.find(pro => pro.projectId === value.id);
            if (!stuPro) {
                continue;
            }
            let proData = { description: parser(value.description), date: stuPro.date, points: [] };

            for (let y = 1; y <= 9; y++) {
                proData['skill' + y] = false;
            }
            for (let skill of value.skills) {
                if (skill.activity == actNum && stuPro.validation.findIndex(item => item === skill.activity + ':' + skill.skill) !== -1) {
                    proData.points.push(parser(skill.description));
                    proData['skill' + skill.skill] = true;
                }
            }
            if (data.activites[x].projet1.length < 5) {
                proData.number = data.activites[x].projet1.length + 1;
                data.activites[x].projet1.push(proData);
            } else if (data.activites[x].projet2.length < 5) {
                proData.number = data.activites[x].projet2.length + 1;
                data.activites[x].projet2.push(proData);
            }
        }

        for (let y = 1; y <= 2; y++) {
            while (data.activites[x]['projet' + y].length < 5) {
                data.activites[x]['projet' + y].push({
                    number: data.activites[x]['projet' + y].length + 1,
                    description: '',
                    points: [],
                    date: ''
                });

            }

        }
    }

    return data;
}
/**
 * 
 * @param {*} data 
 * @param {PizZip} zipBundle 
 */
export function createDocx(data, zipBundle = null) {
    return new Promise((resolve) => {
       
    getBinaryContent(process.env.BASE_URL + 'templates/template.docx', function (err, content) {
        let imageModule = new ImageModule({
            getImage(tagValue, tagName) {
                return base64DataURLToArrayBuffer(tagValue);
            },
            getSize(img, tagValue, tagName) {
                if(tagName == 'signatureResponsable') {
                    return [200, 100];
                }
                return [75,75];
            }
        })
        let zip = new PizZip(content);
        let docx = new Docxtemplater();
        let doc = docx.loadZip(zip);
        doc.attachModule(imageModule);
        doc.setData(data);

        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render()

        var out = doc.getZip().generate({
            type: zipBundle?'base64':'blob',
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //Output the document using Data-URI
        if(zipBundle) {
            zipBundle.file("Livret-Evaluation-" + data.name + "-" + data.firstName + "-" + data.referentiel + ".docx", out, {
                base64: true
            })
        }else {
            saveAs(out, "Livret-Evaluation-" + data.name + "-" + data.firstName + "-" + data.referentiel + ".docx");

        }
        resolve();
    }); 
})
}

function base64DataURLToArrayBuffer(dataURL) {
    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
    if (!base64Regex.test(dataURL)) {
      return false;
    }
    const stringBase64 = dataURL.replace(base64Regex, "");
    let binaryString= Buffer.from(stringBase64, "base64").toString("binary");
   
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes.buffer;
  }