import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import store from './store';
import referentiel from './referentiel.json';

firebase.initializeApp({
    apiKey: "AIzaSyBgyqqVGF6_iUXVU3t-aAVHnsGI6JB0wyY",
    authDomain: "late-birds.firebaseapp.com",
    databaseURL: "https://late-birds.firebaseio.com",
    projectId: "late-birds",
    storageBucket: "late-birds.appspot.com",
    messagingSenderId: "47310751304"
});

export const db = firebase.firestore();


firebase.auth().onAuthStateChanged(user => {
    if (user) {
        store.commit('CHANGE_LOGGED', true);


        db.collection("student").onSnapshot(data => {
            let students = [];
            data.forEach(doc => {
                let student = Object.assign({ id: doc.id }, doc.data());

                students.push(transformStudent(student));
            });
            store.commit('CHANGE_STUDENTS', students);
        });


        db.collection("project").onSnapshot(data => {
            let projects = [];
            data.forEach(doc =>
                projects.push(Object.assign({ id: doc.id }, doc.data()))
            );
            store.commit('CHANGE_PROJECTS', projects);
        });

    } else {
        store.commit('CHANGE_LOGGED', false);
    }
});



function transformStudent(student) {
    if (!student.projects) {
        student.projects = [];
    }
    if (!student.delays) {
        student.delays = [];
    }
    student.progression = function () {
        let done = {};

        for (let item of this.projects) {
            let project = store.state.projects.find(pro => pro.id === item.projectId);
            if (project) {
                if(!done[project.referential]) {
                    done[project.referential] = [];
                }
                for (let validation of item.validation) {

                    if (!done[project.referential].find(val => validation === val)) {
                        done[project.referential].push(validation);
                    }
                }
            }
        }
        let keys = Object.keys(done);
        if(keys.length === 0) {
            return [{ref: 'Aucune', progression: 'N/A'}]
        }
        return keys.map(key => {
            return {ref:key, progression:done[key].length+'/'+referentiel[key].competences.length};
        });
        // return done.length + "/" + referentiel.DWWM.length;
    };
    return student;
}