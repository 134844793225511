<template>
  <div>
    <v-navigation-drawer permanent clipped app>
      <v-list>
        <v-list-tile :class="{ active: promo === selected }" v-for="(promo, index) in promos" :key="index"
          @click="selectPromo(promo)">
          <v-list-tile-content>
            <v-list-tile-title>Promo {{ promo }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-container grid-list-md>
        <v-btn color="accent" @click="edit(null)">Ajouter</v-btn>
        <v-btn color="warning" v-if="selected" @click="showGlobal = true">Actions Globales</v-btn>
        <v-layout row wrap>
          <v-flex xs12 md4 v-for="student in filteredStudents" :key="student.id">
            <v-card color="#EF5350" class="white--text">
              <v-card-title primary-title>
                <div>
                  <div class="headline">{{ student.name }} {{ student.firstName }}
                    <v-chip class="pronoun" @click="changePronoun(student)" v-if="!student.female" color="#B9F6CA">Il</v-chip>
                    <v-chip class="pronoun" @click="changePronoun(student)" v-if="student.female" color="#F4FF81">Elle</v-chip>
                  </div>
                  <span>Progression :
                    <v-chip color="accent" v-for="(item, index) in student.progression()" :key="index">
                      {{ item.ref }}: {{ item.progression }}
                    </v-chip>
                  </span>
                </div>
              </v-card-title>
              <v-card-actions>
                <v-btn flat dark @click="project(student)">Projets</v-btn>
                <v-btn flat dark @click="edit(student)">Modifier</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-dialog v-model="showAddStudent" persistent>
        <v-btn fab small color="primary" right absolute @click="showAddStudent = false">
          <v-icon>close</v-icon>
        </v-btn>
        <add-student-vue :student="editStudent" />
      </v-dialog>

      <v-dialog v-model="showProjects" persistent>
        <v-btn fab small color="primary" right absolute @click="showProjects = false">
          <v-icon>close</v-icon>
        </v-btn>
        <project-student-vue :studentProp="editStudent" />
      </v-dialog>

      <v-dialog v-model="showGlobal" persistent>
        <v-btn fab small color="primary" right absolute @click="showGlobal = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card>
          <v-card-title class="headline">Actions Globales</v-card-title>

          <v-card-text>
            <v-autocomplete v-model="globProject" :items="projects" item-text="name" return-object
              label="Choisir un projet"></v-autocomplete>
            <v-text-field name="globDate" label="Date du projet" v-model="globDate" v-if="globProject"></v-text-field>
            <v-btn :disabled="!globProject" color="warning" @click="globValidate()">Valider pour la promo</v-btn>
            <v-btn :disabled="!globProject" color="error" @click="globRemove()">Supprimer pour la promo</v-btn>
            <v-btn color="success" @click="globExport()">Exporter la promo en docx</v-btn>
             <v-select style="display:inline-flex" :items="refList" v-model="ref" label="Référentiel du Livret"></v-select>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-content>
  </div>
</template>

<script>
import referentiel from "../referentiel.json";
import { mapState, mapGetters } from "vuex";
import AddStudentVue from "../components/AddStudent.vue";
import ProjectStudentVue from "../components/ProjectStudent.vue";
import { createDocx, createData, curDate } from "../docx-exporter";
import PizZip from "pizzip";
import { saveAs } from "file-saver";


export default {
  name: 'student-page',
  components: {
    ProjectStudentVue,
    AddStudentVue
  },
  computed: {
    ...mapState(["students", "projects"]),
    ...mapGetters(["promos"]),
    filteredStudents() {
      return this.students.filter(student => student.promo === this.selected);
    }
  },
  data() {
    return {
      ref: null,
      newProm: "",
      selected: null,
      showAddStudent: false,
      editStudent: null,
      showProjects: false,
      showGlobal: false,
      globProject: null,
      refList: Object.keys(referentiel),
      globDate: null
    };
  },
  methods: {
    selectPromo(promo) {
      this.selected = promo;
    },
    edit(student) {
      this.editStudent = student;
      this.showAddStudent = true;
    },
    project(student) {
      this.editStudent = student;
      this.showProjects = true;
    },
    changePronoun(student) {
      if(student.female == undefined) {
        student.female = true
      } else {
        student.female = !student.female;
      }
      this.$store
        .dispatch("updateStudent", {
          data: { female: student.female },
          id: student.id
        })
        .catch(err => console.log(err));
    },
    globValidate() {
      for (let student of this.filteredStudents) {
        if (!student.projects) {
          student.projects = [];
        }
        if (
          !student.projects.find(item => item.projectId === this.globProject.id)
        ) {
          let stuPro = {
            projectId: this.globProject.id,
            date: this.globDate ? this.globDate : curDate(),
            validation: []
          };

          for (let comp of this.globProject.skills) {
            stuPro.validation.push(comp.activity + ":" + comp.skill);
          }
          student.projects.push(stuPro);

        }
      }
      this.$store.dispatch('updateStudentsProjects', this.filteredStudents);
      this.globProject = null;
    },
    globRemove() {
      for (let student of this.filteredStudents) {

        if (!student.projects) {
          student.projects = [];
        }
        student.projects = student.projects.filter(item => item.projectId !== this.globProject.id, this);

        this.$store.dispatch('updateStudentsProjects', this.filteredStudents).then(() => this.globProject = null);

      }
    },
    async globExport() {
      let localDefault = JSON.parse(localStorage.getItem('default'));
      let zip = new PizZip();
      for (let student of this.filteredStudents) {
        const dataLivret = createData(
          student,
          student.projects ? student.projects.map(pro =>
              this.projects.find(item => item.id === pro.projectId)
            ) : [],
          student.female,
          localDefault.formateur,
          localDefault.responsable,
          this.ref,
          localDefault.location
        );
        dataLivret.signature = localDefault.signature;
       dataLivret.signatureResponsable = localDefault.signatureResponsable;

        await createDocx(dataLivret, zip)
      }
      saveAs(zip.generate({type:'blob'}), 'livret-'+this.ref+'-promo'+this.selected+'.zip');
    }
  }
};
</script>

<style>
.pronoun {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.4em;
}
</style>
