<template>
  <v-app>
    <v-toolbar  dark color="primary" clipped-left app>
      <v-toolbar-title>Compétences Projet</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-if="logged" flat to="/">Projets</v-btn>
        <v-btn v-if="logged" flat to="/student">Apprenant·e·s</v-btn>

        <v-btn flat v-if="!logged" @click="login()">Login</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <router-view v-if="logged"/>
    <v-content  v-if="!logged">
      <p>Veuillez vous connecter</p>

    </v-content>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";

export default {
  computed: {
    ...mapState(["logged"])
  },
  methods: {
    login() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider);
    }
  }
};
</script>
