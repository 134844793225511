<template>
  <v-card>
    <v-card-title class="headline">Projets de {{ student.firstName }} {{ student.name }}</v-card-title>

    <v-card-text>
      <textarea ref="copy" class="copy-textarea" v-model="copy"></textarea>
      <v-btn color="success" @click="save()">Save</v-btn>
      <v-btn color="accent" @click="openExport()">Exporter</v-btn>
      <v-autocomplete v-model="newProject" :items="filteredProjects" item-text="name" return-object
        label="Ajouter un projet">
        <template v-slot:append-outer>
          <v-btn fab small color="success" @click="addProject()" :disabled="!newProject">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
      </v-autocomplete>
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(project, i) in student.projects" :key="i">
          <template v-slot:header>
            <div>
              {{ projectRefs[i].name }}
              <v-btn flat @click.stop="removeProject(i)">supprimer</v-btn>
            </div>
          </template>
          <v-card>
            <v-card-text>
              <v-text-field name="date" label="Date du projet (mm/aaaa)" v-model="project.date"></v-text-field>
              <p>{{ projectRefs[i].description }}</p>
              <v-list>
                <v-list-tile v-for="(item, itemIndex) in projectRefs[i].skills" :key="itemIndex">
                  <v-list-tile-action>{{ item.activity }}-{{ item.skill }}</v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.description }}</v-list-tile-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-icon large @click="validate(project, item)"
                      :color="validated(project, item) !== -1 ? 'green' : 'grey'">check_circle</v-icon>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-card-text>
    <v-dialog v-model="showExport">
      <v-card>
        <v-card-title class="headline">Exporter au format Docx ou HTML</v-card-title>

        <v-card-text>
          <v-text-field name="responsable" label="Responsable du centre de formation" v-model="responsable">
          </v-text-field>

          <v-text-field name="formateur" label="Formateur·ice" v-model="formateur"></v-text-field>
          <v-text-field name="location" label="Lieu de formation" v-model="location"></v-text-field>
          <v-select :items="refList" v-model="ref" label="Référentiel du Livret"></v-select>
          <div>

            <label for="signature">Signature Formateur·ice : </label>
            <input id="signature" type="file" accept="image/*" @change="signatureChange($event)">
            <img width="100" :src="signature" alt="Signature Actuelle" v-if="signature">
          </div>
          <div>

            <label for="signatureResponsable">Signature Responsable : </label>
            <input id="signatureResponsable" type="file" accept="image/*" @change="signatureChange($event, true)">
            <img width="100" :src="signatureResponsable" alt="Signature Responsable Actuelle" v-if="signatureResponsable">
          </div>

          <v-switch label="Pronom féminin" @change="changePronoun()" v-model="student.female"></v-switch>
          <v-radio-group v-model="exportType">
            <v-radio label="DOCX" value="docx"></v-radio>
            <v-radio label="HTML" value="html"></v-radio>
          </v-radio-group>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" flat @click="exportDoc()">Exporter</v-btn>
            <v-btn color="error" flat @click="showExport = false">Annuler</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLivret" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-btn fab small color="secondary" right fixed @click="showLivret = false" class="no-print">
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn fab small color="accent" left fixed @click="print()" class="no-print">
        <v-icon>print</v-icon>
      </v-btn>
      <div class="white">
        <LivretExport :data="dataLivret" v-if="showLivret" />
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { createDocx, createData, curDate } from "../docx-exporter";
import def from "../default.json";
import referentiel from "../referentiel.json";
import LivretExport from "./LivretExport";

export default {
  components: {
    LivretExport
  },
  props: ["studentProp"],
  computed: {
    ...mapState(["projects"]),
    filteredProjects() {
      return this.projects.filter(project => {
        if (
          this.student.projects &&
          this.student.projects.find(pro => pro.projectId === project.id)
        ) {
          return false;
        }
        return true;
      });
    },
    projectRefs() {
      return this.student.projects
        ? this.student.projects.map(pro =>
          this.projects.find(item => item.id === pro.projectId)
        )
        : [];
    },
    copy() {
      return `Livret d'évaluation ${this.student.firstName} ${this.student.name}.pdf`
    }
  },
  data() {
    return {
      student: {},
      newProject: null,
      responsable: def.manager,
      formateur: def.teacher,
      signature: null,
      signatureResponsable: null,
      female: false,
      showExport: false,
      showLivret: false,
      refList: Object.keys(referentiel),
      ref: def.referential,
      location: def.lieu,
      dataLivret: null,
      exportType: 'html'
    };
  },
  watch: {
    studentProp: function (student) {
      this.student = Object.assign({}, student);
    }
  },
  mounted() {
    let localDefault = JSON.parse(localStorage.getItem('default'));
    if (localDefault) {
      this.formateur = localDefault.formateur;
      this.responsable = localDefault.responsable;
      this.location = localDefault.location;
      this.signature = localDefault.signature;
      this.signatureResponsable = localDefault.signatureResponsable;
    }
  },
  methods: {
    openExport() {
      this.$refs['copy'].select();
      if (!navigator.clipboard) {
        document.execCommand('copy');

      } else {
        navigator.clipboard.writeText(this.copy);
      }
      this.showExport = true;
    },
    addProject() {
      if (!this.student.projects) {
        this.student.projects = [];
      }
      this.student.projects.push({
        projectId: this.newProject.id,
        validation: [],
        date: curDate()
      });
      this.newProject = null;
    },
    removeProject(index) {
      this.student.projects.splice(index, 1);
      this.$store
        .dispatch("updateStudent", {
          data: { projects: this.student.projects },
          id: this.student.id
        })
        .catch(err => console.log(err));
    },
    validated(project, skill) {
      return project.validation.findIndex(
        item => item === skill.activity + ":" + skill.skill
      );
    },
    validate(project, skill) {
      let index = this.validated(project, skill);
      if (index !== -1) {
        project.validation.splice(index, 1);
      } else {
        project.validation.push(skill.activity + ":" + skill.skill);
      }
    },
    save() {
      this.$store
        .dispatch("updateStudent", {
          data: { projects: this.student.projects },
          id: this.student.id
        })
        .catch(err => console.log(err));
    },
    changePronoun() {
      this.$store
        .dispatch("updateStudent", {
          data: { female: this.student.female ? true : false },
          id: this.student.id
        })
        .catch(err => console.log(err));
    },
    exportDoc() {
      localStorage.setItem('default',
        JSON.stringify({
          formateur: this.formateur,
          responsable: this.responsable,
          location: this.location,
          signature: this.signature,
          signatureResponsable: this.signatureResponsable
        })
      );

      this.dataLivret = createData(
        this.student,
        this.projectRefs,
        this.student.female,
        this.formateur,
        this.responsable,
        this.ref,
        this.location
      );
      this.dataLivret.signature = this.signature;
      this.dataLivret.signatureResponsable = this.signatureResponsable;
      if (this.exportType === 'html') {
        this.showLivret = true;
      }
      if (this.exportType === 'docx') {
        createDocx(this.dataLivret);
      }



    },
    print() {
      var domClone = document.querySelector(".livret").cloneNode(true);

      var $printSection = document.getElementById("printSection");

      if (!$printSection) {
        $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);
      window.print();
    },
    signatureChange(event, resp = false) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        if(resp) {
          this.signatureResponsable = reader.result;
        }else {
          this.signature = reader.result;
        }
      };


    }
  }
};
</script>

<style>
@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #printSection,
  #printSection * {
    visibility: visible;
  }

  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.copy-textarea {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
</style>
