<template>
  <div class="livret">
       <table class="table-content">
        <thead>
            <tr>
                <td>
                    <div class="header-space">&nbsp;</div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="content">
                        <section class="page">
                            <h2 class="section-titre">Titre professionnel</h2>
                            <h3 class="titre-pro">{{data.titre}} <span class="niveau">niveau {{data.niveau}}</span>
                            </h3>

                            <section class="dates">
                                <p><span>Arrêté du :</span> {{data.dateArrete}}</p>
                                <p><span>J.O. du :</span> {{data.dateJO}}</p>
                                <p><span>Date d'effet au :</span> {{data.dateEffet}}</p>
                            </section>

                            <section class="organisme blocks-arrow">
                                <div class="left-block">
                                    <label for="organisme">Organisme de formation</label>

                                    <label for="lieu">Lieu de formation</label>
                                </div>
                                <div class="right-block">
                                    <input type="text" placeholder="Entrez le nom de l'organisme ici." id="organisme" value="Simplon.co">
                                    <input type="text" placeholder="Entrez le lieu ici." id="lieu" :value="data.lieu">
                                </div>
                            </section>

                            <section class="candidate blocks-arrow">

                                <div class="left-block">
                                    <span class="bold">Candidat(e) :</span>
                                    <label for="nom">Nom</label>
                                    <label for="prenom">Prénom</label>
                                    <label for="date-naissance">Date de naissance</label>
                                </div>
                                <div class="right-block">
                                    <div>
                                        <label for="mme">Mme</label>
                                        <input type="checkbox" name="sexe" id="mme" :checked="data.female">

                                        <label for="m">M.</label>
                                        <input type="checkbox" name="sexe" id="m" :checked="!data.female">
                                    </div>
                                    <input type="text" placeholder="Entrez le nom de famille ici." id="nom" :value="data.name">
                                    <input type="text" placeholder="Entrez le prénom ici." id="prenom" :value="data.firstName">
                                    <input type="text" placeholder="Cliquez ici pour choisir une date"
                                        id="date-naissance" :value="data.birthdate">
                                </div>
                            </section>

                        </section>
                        <section class="page">
                            <h2 class="section-titre">Présentation du dossier</h2>

                            <p class="bordered">
                                Les évaluations passées en cours de formation décrites dans les fiches qui suivent ont
                                été mises en œuvre
                                en référence aux critères issus des référentiels du titre professionnel, pour les
                                sessions d’examen « Titre »,
                                « CCP » et « CCS » telles que prévues par l’arrêté du 22 décembre 2015 relatif aux
                                conditions de délivrance
                                du titre professionnel du ministère chargé de l’emploi.
                            </p>
                            <p class="bottom">
                                Le <span class="bold">Guide de mise en œuvre</span> des Evaluations passées en cours de
                                formation est à
                                télécharger sur le site du ministère de
                                l’emploi: <a href="http://travail-emploi.gouv.fr/">http://travail-emploi.gouv.fr/</a>
                                (rubrique <a
                                    href="http://www.certification.afpa.fr/certification-annexes.html">Documents
                                    techniques</a>).<br>
                                Il comporte un mode d’emploi du présent Livret d’évaluations passées en cours de
                                formation.
                            </p>
                        </section>
                        <!-- loop here -->
                        <section v-for="activite in data.activites" :key="activite.actNum">
                            <h2 class="activite-titre">Fiche de résultats des évaluations</h2>
                            <div class="activite">
                                <p class="color-primary">Activité-type {{activite.actNum}}</p>
                                <p>{{activite.actName}}</p>
                            </div>
                            <div class="bordered">
                                <h3>Compétences : </h3>
                                <ol>
                                    <li v-for="(comp, index) in activite.comps" :key="index">{{comp.text}}</li>
                                </ol>
                            </div>
                            <section class="projets">
                                <div class="line head">
                                    <div>Description des évaluations mises en œuvre</div>
                                    <div>Dates</div>
                                    <div>Compétences évaluées (cochez)</div>
                                </div>
                                <!-- loop here -->
                                <div class="line" v-for="(projet,proindex) in activite.projet1" :key="proindex">
                                    <div>{{projet.number}} <p class="editable" contenteditable>
                                        {{projet.description}}
                                        <ul>
                                            <li v-for="(point,poindex) in projet.points" :key="poindex">
                                                {{point}}
                                            </li>
                                        </ul>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="editable" contenteditable>
                                            {{projet.date}}
                                        </p>
                                    </div>
                                    <div class="comps">
                                        <div class="coche-comp">
                                            <div>
                                                1 <input type="checkbox" :checked="projet.skill1">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                2 <input type="checkbox" :checked="projet.skill2">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                3 <input type="checkbox" :checked="projet.skill3">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                4 <input type="checkbox" :checked="projet.skill4">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                5 <input type="checkbox" :checked="projet.skill5">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                6 <input type="checkbox" :checked="projet.skill6">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                7 <input type="checkbox" :checked="projet.skill7">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                8 <input type="checkbox" :checked="projet.skill8">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                9 <input type="checkbox" :checked="projet.skill9">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section class="satisfaction">
                                    <p class="bold">
                                        Lors de l’évaluation ou des évaluations passée(e) en cours de formation, le/la
                                        candidat(e) est considéré(e):
                                    </p>
                                    <p><input type="checkbox" checked>Avoir satisfait aux critères issus des référentiels du
                                        titre professionnel attendus pour la réalisation de cette activité-type.</p>
                                    <p><input type="checkbox">Ne pas avoir satisfait aux critères issus des référentiels
                                        du titre professionnel.</p>
                                </section>
                                <section class="page">
                                    <div class="remarque">
                                        <label>Si le candidat n'a pas satisfait aux critères issus des référentiels,
                                            notez ci-dessous les points d'attention et précisions éventuelles.</label>
                                        <div>
                                            <p class="editable" contenteditable></p>
                                        </div>
                                    </div>
                                    <div class="remarque">
                                        <label>Compétences à réévaluer: <br> <span>(Voir évaluations complémentaires
                                                ci-après.)</span>
                                        </label>
                                        <div>
                                            <p class="editable" contenteditable></p>
                                        </div>
                                    </div>
                                    <table class="table-signature">
                                        <thead>
                                            <tr>
                                                <th>Formateur(s) évaluateur(s)</th>
                                                <th></th>
                                                <th>Visa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label>Nom ►</label>
                                                    <input type="text" :value="data.formateur">
                                                </td>
                                                <td>
                                                    <label>Date ►</label>
                                                    <input type="text" :value="data.today">
                                                </td>
                                                <td height="60">
                                                    <img v-if="data.signature" :src="data.signature" class="signature">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>Nom ►</label>
                                                    <input type="text">
                                                </td>
                                                <td>
                                                    <label>Date ►</label>
                                                    <input type="text">
                                                </td>
                                                <td height="60">
                                                    <img v-if="data.signatureResponsable" :src="data.signatureResponsable" class="signature responsable">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </section>
                            <h2>Evaluations complémentaires <span>(si nécessaire)</span></h2>
                            <section class="projets">
                                <div class="line head">
                                    <div>Description des évaluations mises en œuvre</div>
                                    <div>Dates</div>
                                    <div>Compétences évaluées (cochez)</div>
                                </div>
                                <!-- loop here -->
                                <div class="line" v-for="(projet,proindex) in activite.projet2" :key="proindex">
                                    <div>{{projet.number}} <p class="editable" contenteditable>
                                        {{projet.description}}
                                        <ul>
                                            <li v-for="(point,poindex) in projet.points" :key="poindex">
                                                {{point}}
                                            </li>
                                        </ul>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="editable" contenteditable>
                                            {{projet.date}}
                                        </p>
                                    </div>
                                    <div class="comps">
                                        <div class="coche-comp">
                                            <div>
                                                1 <input type="checkbox" :checked="projet.skill1">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                2 <input type="checkbox" :checked="projet.skill2">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                3 <input type="checkbox" :checked="projet.skill3">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                4 <input type="checkbox" :checked="projet.skill4">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                5 <input type="checkbox" :checked="projet.skill5">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                6 <input type="checkbox" :checked="projet.skill6">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                7 <input type="checkbox" :checked="projet.skill7">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                8 <input type="checkbox" :checked="projet.skill8">
                                            </div>
                                        </div>
                                        <div class="coche-comp">
                                            <div>
                                                9 <input type="checkbox" :checked="projet.skill9">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section class="satisfaction">
                                    <p class="bold">
                                        Lors de l’évaluation ou des évaluations passée(e) en cours de formation, le/la
                                        candidat(e) est considéré(e):
                                    </p>
                                    <p><input type="checkbox" checked>Avoir satisfait aux critères issus des référentiels du
                                        titre professionnel attendus pour la réalisation de cette activité-type.</p>
                                    <p><input type="checkbox">Ne pas avoir satisfait aux critères issus des référentiels
                                        du titre professionnel.</p>
                                </section>
                                <div class="remarque">
                                    <label>Observations</label>
                                    <div>
                                        <p class="editable" contenteditable></p>
                                    </div>
                                </div>

                                <table class="table-signature">
                                    <thead>
                                        <tr>
                                            <th>Formateur(s) évaluateur(s)</th>
                                            <th></th>
                                            <th>Visa</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>Nom ►</label>
                                                <input type="text" :value="data.formateur">
                                            </td>
                                            <td>
                                                <label>Date ►</label>
                                                <input type="text" :value="data.today">
                                            </td>
                                            <td height="60">
                                                <img v-if="data.signature" :src="data.signature" class="signature">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>Nom ►</label>
                                                <input type="text">
                                            </td>
                                            <td>
                                                <label>Date ►</label>
                                                <input type="text">
                                            </td>
                                            <td height="60">
                                                <img v-if="data.signatureResponsable" :src="data.signatureResponsable" class="signature responsable">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                        </section>
                        <section class="page synthese">
                            <h2>Synthèse des résultats obtenus par le candidat
                                à l’issue du parcours de formation</h2>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Intitulé de l'activité type</th>
                                        <th>Compétences professionnelles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template  v-for="activite in data.activites">
                                    <tr :key="'tr1' + activite.actNum">
                                        <td>{{activite.actName}}</td>

                                        <td>
                                            <ol>
                                                <li v-for="(comp,index) in activite.comps" :key="index">{{comp.text}}</li>

                                            </ol>
                                        </td>
                                    </tr>
                                    <tr :key="'tr2' + activite.actNum">
                                        <td colspan="2">
                                            <label><span class="color-primary">►</span>L'activité {{activite.actNum}} est maîtrisée :
                                            </label>
                                            OUI <input type="checkbox" checked>
                                            NON <input type="checkbox">
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </section>
                        <section class="page">
                            <div class="remarque">
                                <label>Observations</label>
                                <div>
                                    <p class="editable" contenteditable></p>
                                </div>
                            </div>

                            <table class="table-signature">
                                <thead>
                                    <tr>
                                        <th>Formateur(s) / Evaluateur(s)</th>
                                        <th></th>
                                        <th>Visa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>Nom ►</label>
                                            <input type="text" :value="data.formateur">
                                        </td>
                                        <td>
                                            <label>Date ►</label>
                                            <input type="text" :value="data.today">
                                        </td>
                                        <td height="60">
                                            <img v-if="data.signature" :src="data.signature" class="signature">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Nom ►</label>
                                            <input type="text">
                                        </td>
                                        <td>
                                            <label>Date ►</label>
                                            <input type="text">
                                        </td>
                                        <td height="60"> 
                                            <img v-if="data.signatureResponsable" :src="data.signatureResponsable" class="signature responsable">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table-signature">
                                <thead>
                                    <tr>
                                        <th>Représentant de l'organisme de formation</th>
                                        <th></th>
                                        <th>Visa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>Nom ►</label>
                                            <input type="text" :value="data.responsable">
                                        </td>
                                        <td>
                                            <label>Date ►</label>
                                            <input type="text" :value="data.today">
                                        </td>
                                        <td>
                                            <img v-if="data.signatureResponsable" :src="data.signatureResponsable" class="signature responsable">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space">&nbsp;</div>
                </td>
            </tr>
        </tfoot>
    </table>
    <header>

        <img class="logo" src="../assets/logo.png" alt="logo république française">

        <h1>Livret d'évaluation passées en cours de formation</h1>
    </header>


    <footer>
        <table class="borderised">
            <tr>
                <td>SIGLE</td>
                <td>Type de document</td>
                <td>Code titre</td>
                <td>Milésime</td>
                <td>Date JO</td>
                <td>Date de mise à jour</td>
            </tr>
            <tr>
                <td>DWWM</td>
                <td>Livret d'évaluation passées en cours de formation</td>
                <td>TP-01280</td>
                <td>03</td>
                <td>17/04/2018</td>
                <td>04/05/2018</td>
            </tr>
        </table>
    </footer>


  </div>
</template>

<script>
export default {
    props: [
        "data"
    ]

}
</script>

<style>
.livret  {
    width: 20cm;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
}
header, .header-space{
  height: 140px;

}
footer, .footer-space {
  height: 50px;
}
.table-content {
  width: 100%;
}

input {
  border: none;
}

h2 span {
  font-weight: normal;
  font-size: 0.6em;
}

.bold {
  font-weight: bold;
}

.borderised td {
  border: 1px solid black;
  margin:0;
}

.page {
  height: 27cm;
  position: relative;
}

.bordered {
  border: 3px solid lightgray;
  font-size: 0.8em;
  padding: 20px;
}

.bottom {
  margin-top: 17cm;
  font-size: 0.8em;
}

.color-primary {
  color: #d60093;
}


.editable {
  min-height: 150px;
  position: relative;
  margin: 0;

}
.editable:not(:focus):empty::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: gray;
  content: "Cliquez ici";
}

/* HEADER */
header .logo {
  width: 15%;
}

header h1 {
  text-transform: uppercase;
  text-align: center;
  width: 80%;
  border-bottom: 3px solid #d60093;
  margin: 0;
  padding-top: 20px;
}

header {
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 20cm;
  background: white;
}

/* FOOTER */
footer {
  position: fixed;
  bottom: 0;
  background: white;
  width: 20cm;
}

footer table {
  font-size: 0.6em;
  width: 100%;
  text-align: center;
}

.section-titre {
  text-align: center;
  color: white;
  background-color: #d60093;
  width: 100%;
  position: relative;
  font-family: sans-serif;
  font-size: 1.3em;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 20px;
}

.section-titre::after {
  background-color: #d60093;
  content: " ";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 4px;
  left:0;
}

/* Présentation titre */

.titre-pro {
  text-align: center;
  font-weight: normal;
  font-family: Arial;

}

.titre-pro .niveau {
  display: block;
  text-transform: capitalize;
  font-size: 0.7em;
  font-weight: bold;
}

.dates {
  text-align: center;
  border: 3px solid lightgray;
  font-size: 1.2em;
  margin-bottom: 40px;
}

.dates span {
  font-size: 0.7em;
}

.blocks-arrow {
  border-left: 5px solid lightgray;
  padding-left: 5px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blocks-arrow>div>* {
  display:block;
  margin:5px;
  position: relative;
}

.blocks-arrow>div>input {
  width: 100%;
}

.blocks-arrow .left-block {
  width: 30%;
  font-style: italic;
  position: relative;
}

.blocks-arrow .left-block label::after {
  content: "►";
  font-size: 0.4em;  
  position: absolute;
  right:0;
  top:5px;
}

.blocks-arrow .right-block {
  width: 70%;
  color: black;
}

.organisme {
  color: #404040;
}

.candidate {
  color: #d60093;
  border-color: #d60093;
}

/*
Compétences
*/
.activite-titre {
  color: white;
  background-color: #d60093;
  width: 100%;
  text-indent: 10px;
  position: relative;
  font-family: sans-serif;
  font-size: 1.3em;
  padding-top: 6px;
  padding-bottom: 1px;
  margin-bottom: 0;
}

.activite {
  display:flex;
  width: 100%;
  border-bottom: 5px solid #d60093;
  margin-bottom: 10px;
}

.activite p {
  margin: 0;
  padding: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

.activite p:first-child {
  white-space: nowrap;
}


.comps {
  display: flex;
  flex-wrap: wrap;
}
.comps .coche-comp {
  width:31.9%;
  border: 1px solid black;
  padding: 16px 0;
  text-align: center;
}

.projets {
  margin-top: 20px;
}

.projets .head {
  font-weight: bold;
}

.projets .head div {
  
  padding-top: 10px;
}

.projets .line {
  display: flex;
  flex-wrap: wrap;
}

.line>div {
  padding: 5px;
  border: 1px solid black;
}

.line div .editable {
  float: right;
  width: 90%;
}

.line div>input:not([type="checkbox"]) {
  height: 100%;
}

.line>div:nth-child(1) {
  width: 65%;
}

.line>div:nth-child(2) {
  width: 15%;
}

.line>div:nth-child(3) {
  width: 20%;
  padding: 0;
}

.projets .satisfaction {
  font-size: 0.85em;
}

.remarque {
  margin-top: 20px;
  page-break-inside: avoid;
}

.remarque>div {
  margin:0;
  border-left: 2px solid lightgray;
  border-right: 2px solid lightgray;
}

.remarque label {
  margin:0;
  display: block;
  border-left: 3px solid #d60093;
  border-bottom: 2px solid #d60093;
  font-weight: bold;
  padding: 3px;
  font-size: 0.9em;
}
.remarque label>span {
  font-weight: normal;
  color: gray;
}

.remarque .editable {
  top: 5px;
  margin: auto;
  width: 90%;
  min-height: 260px;
}

.remarque .editable::after {
  position: absolute;
  top: 5px;
  left: -30px;
  content: "►";
  color: #d60093;
}


/* Signature */

.table-signature {
width: 100%;
}

.table-signature th:first-child {
  text-align: left;
}

.table-signature td {
  border: 1px solid gray;
  padding: 3px 10px;
  
}

.table-signature td:first-child {
  border-left: 3px solid #d60093;
}

.table-signature .signature {
  width: 60px;
  text-align: center;
}
.table-signature .signature.responsable {
  width: 200px;
  text-align: center;
}

.table-signature input {
  width: 75%;
}

/* Synthèse */
.synthese h2 {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.7em;
  border-top: 2px solid gray;
  border-bottom: 3px solid gray;
  padding: 5px 0;
  margin-bottom: 60px;
}

.synthese h2::after {
  content: " ";
  width: 100%;
  height: 30px;
  background-color: lightgray;
  border-bottom: 3px solid #d60093;
  position: absolute;
  bottom: -36px;
  left:0;
}

.synthese table thead {
  text-align: left;
  background-color: lightgray;
}
.synthese td {
  border: 1px solid gray;
  padding: 5px;
}

.synthese label {
  margin-right: 30px;
}

.synthese th {
  border: 1px solid gray;
  padding: 5px;
}

.synthese th:first-child {
  width: 30%;
}

</style>