import Vue from 'vue'
import Vuex from 'vuex'
import { db } from "./plugin-firebase";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    projects: [],
    students: []
  },
  getters: {
    promos(state) {
      return state.students.reduce((prev, cur) => {
        if(!prev.find(prom => cur.promo === prom)) {
          prev.push(cur.promo);
        }
        return prev;
      }, [])
    }
  },
  mutations: {
    CHANGE_LOGGED(state, payload) {
      state.logged = payload;
    },
    CHANGE_PROJECTS(state, payload) {
      state.projects = payload;
    },
    CHANGE_STUDENTS(state, payload) {
      state.students = payload;
    }
  },
  actions: {
    addStudents({commit}, {students, promo}) {
      let batch = db.batch();
      for (let toAdd of students) {
        let studRef = db.collection("student").doc();
        let [name, firstName, birthdate] = toAdd.split(",");

        batch.set(studRef, {
          name: name.trim(),
          firstName: firstName.trim(),
          birthdate: birthdate.trim(),
          promo: promo,
          projects: []
        });
      }
      batch.commit();
    },
    addStudent({commit}, student) {
      return db.collection('student').add(student);
    },
    updateStudent({commit}, {data, id}) {
      return db.collection('student').doc(id).update(data);
    },
    updateStudentsProjects({commit}, students){
      let batch = db.batch();
      let colRef = db.collection('student');
      for (const student of students) {
        batch.update(colRef.doc(student.id), {projects: student.projects});
      }
      batch.commit();
    },
    removeStudent({commit}, student) {
      return db.collection('student').
        doc(student.id).delete();
    },
    addProject({commit}, project) {
      return db.collection('project').add(project);
    },
    updateProject({commit}, {data, id}) {
      return db.collection('project').doc(id).update(data);
    },
    removeProject({commit}, project) {
      return db.collection('project').
        doc(project.id).delete();
    }
  }
})
